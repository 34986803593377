import React from "react"

import { getCxFromStyles } from "../helpers"

import { Header, Footer, PrivacyPolicy, CookiesBar } from "../components"

import * as styles from "./styles.scss"

const Home = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <section className={cx("section", "header")}>
        <Header darkLogo />
      </section>
      <section className={cx("section", "privacyPolicy")}>
        <PrivacyPolicy />
      </section>
      <section className={cx("section", "footer")}>
        <Footer />
      </section>
      <CookiesBar />
    </div>
  )
}

export default Home
